import { defineStore } from 'pinia'

export const useAuth = defineStore('auth',{
    state: () => ({
      status: localStorage.getItem('authStatus') || 'not-authenticated',  // 'authenticated','not-authenticated', 'authenticating'
      token: localStorage.getItem('authToken') || null,
    }),
    
    actions: {
      setStatus(newStatus, newToken) {
        this.status = newStatus;
        this.token = newToken;
        localStorage.setItem('authStatus', newStatus);
        localStorage.setItem('authToken', newToken);
        
      },
    },
    
})

